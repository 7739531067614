import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Table from "components/Table";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "table"
    }}>{`Table`}</h1>

    <Playground __position={0} __code={'<Table>\n  <Table.Header>\n    <Table.Row>\n      <Table.Col>#</Table.Col>\n      <Table.Col>ID</Table.Col>\n      <Table.Col>Type</Table.Col>\n    </Table.Row>\n  </Table.Header>\n  <Table.Body>\n    <Table.Row>\n      <Table.Col>1</Table.Col>\n      <Table.Col>12345</Table.Col>\n      <Table.Col>Test 1</Table.Col>\n    </Table.Row>\n    <Table.Row>\n      <Table.Col>2</Table.Col>\n      <Table.Col>67890</Table.Col>\n      <Table.Col>Test 2</Table.Col>\n    </Table.Row>\n  </Table.Body>\n</Table>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Table,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Table mdxType="Table">
    <Table.Header>
      <Table.Row>
        <Table.Col>#</Table.Col>
        <Table.Col>ID</Table.Col>
        <Table.Col>Type</Table.Col>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Col>1</Table.Col>
        <Table.Col>12345</Table.Col>
        <Table.Col>Test 1</Table.Col>
      </Table.Row>
      <Table.Row>
        <Table.Col>2</Table.Col>
        <Table.Col>67890</Table.Col>
        <Table.Col>Test 2</Table.Col>
      </Table.Row>
    </Table.Body>
  </Table>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/tables/" target="_blank">Reactstrap Table</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      